<template>
  <div>
    <v-card v-for="layout in data" :key="layout.title" class="py-3 px-2 my-5">
      <v-card-title style="text-align: left">{{ layout.title }}</v-card-title>
      <v-card-text>
        <v-row v-for="(lines, index) in layout.lines" :key="index">
          <v-col
            v-for="item in renderList(lines.columns, lines)"
            :key="item.name"
            cols="12"
            sm="9"
            :lg="convertProps(item.props)"
            :xl="convertProps(item.props)"
            class="col-bordered"
          >
            <Selection
              v-if="item.type == 'selection' && relatorio[`${item.key}`] == '1'"
              :relatorio="relatorio"
              :selection="item"
            />
            <div v-else-if="item.type === 'multiInsertForm'">
              <span class="key-title">{{ item.label }} </span>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th
                        v-for="label in item.linesLabels"
                        :key="label"
                        class="text-left"
                      >
                        {{ label }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="reportItem in JSON.parse(relatorio[`${item.key}`])"
                      :key="reportItem.name"
                    >
                      <td v-for="key in item.lineKeys" :key="key">
                        {{ reportItem[key] }}
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </div>
            <div v-else>
              <span class="key-title">{{ item.label }} </span>
              <span class="value-title">{{ relatorio[item.key] }} </span>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import Selection from './Selection'

export default {
  name: 'ReportLayout',

  components: {
    Selection,
  },

  props: {
    relatorio: {
      type: Object,
      default: () => {
        return {}
      },
    },

    layoutStructure: {
      type: Array,
      default: () => {
        return []
      },
    },
  },

  data() {
    return {
      data: this.layoutStructure,
    }
  },

  mounted() {},
  methods: {
    convertProps(props) {
      if (!props) return '12'

      const listProps = props.split(' ')

      let sizeColumns = listProps.find(item => item[0] === 'c')

      if (!sizeColumns) return ''

      sizeColumns = sizeColumns.replace('c', '')
      return Math.round(12 * (sizeColumns / 100))
    },
    formatLabel(label) {
      if (label[label.length - 1] == '?') {
        return label.slice(0, -1) + ':'
      }
    },

    renderList(list) {
      return list.filter(item => this.relatorio[item.key])
    },
  },
}
</script>

<style scoped>
.key-title {
  font-weight: 500;
  font-size: '16px';
}

.value-title {
  font-size: '16px';
}

.col-bordered {
  border: solid 1px #ccc;
}
</style>
